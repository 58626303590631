/* make variables */
:root {
  --lightgray: #D9D9D9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464D53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor:  #3c3f45;
}

.App {
  background-image: url(data:image/png;base64,);
  mix-blend-mode: overlay;
  background-color: var(--appColor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
::-webkit-scrollbar{
  display: none;
}
.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;

}
.btn{
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 0px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:hover{
  cursor: pointer;
}
.blur{
  background: rgba(253, 120, 43, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;

}